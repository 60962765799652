.app {
  height: 100%;
}

.header {
  min-height: 32px;
  background: #dcdcdc;
  display: flex;
  align-items: center;
  padding-left: 8px;
  font-weight: bold;
}

.header h1 {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.header button {
  margin: 0 3px;
}
.header .btn button:first-child {
  margin-left: 12px;
}

.message {
  background: #ff000087;
  margin-left: 12px;
  padding: 2px 4px;
  border-radius: 2px;
  color: white;
}

.toolbar {
  padding: 0 5px;
  padding-left: 12px;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
}